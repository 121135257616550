<template>
  <div>

    <CRow>
      <CCol lg="12">
        <CCard class="bg-transparent border-0">
        <CCardHeader>
          <slot name="header">
            <!-- <CIcon name="cil-grid"/> --><h4 class="float-left text-white">List My Stake</h4> 
            <a href="/#/stakepanel" class="float-right btn btn-success form-control-sm">Add New Stake</a>

          </slot>
        </CCardHeader>
        <CCardBody class="p-0">

        <CCard accent-color="secondary" v-for="row in items" :key="row.no" v-if="itemlengths>0">
          <CCardBody style="font-size: 11px;" >
            <CRow>
              <CCol>
                <div class="text-left"><strong>{{row.coin_name}}</strong><br><span class="text-secondary">{{row.package_name}}</span></div>
              </CCol>
              <CCol>
                <div class="text-right"><strong><span class='text-info'>{{ row.stake_conversion}}  / {{row.stake_amounts}} USDT</span></strong><br><span class='text-secondary'>{{row.stake_timecreated}} <br> to {{row.stake_timeend}}</span></div>
              </CCol>
            </CRow>
            <!-- <p class="mb-0"><b>Your Earning PerHour : <span class='text-success'>{{row.stake_earning}} {{row.code}}</span></b></p> -->
            <p><b>Your Total Earning  : <span class='text-success'>{{row.stake_total}} {{row.code}}</span></b></p>
            <CRow>
              <CCol lg="6" xs="12" sm="12" class="d-md-down-none ">
                <CDropdown
                    placement="bottom-end"
                    toggler-text="Convert Earning to USDT"
                    color="success"
                    class="m-2 d-inline-block"
                  >
                    <CDropdownItem @click="toWallet(row.stake_total, row.stake_id, row.id_coin)">to Wallet</CDropdownItem>
                    <CDropdownDivider/>
                    <CDropdownItem @click="toDeposit(row.stake_total, row.stake_id, row.id_coin)">to Deposits</CDropdownItem>
                  </CDropdown>
              </CCol>
              <CCol lg="6" xs="12" sm="12" class="text-right  d-md-down-none ">
                <span v-if="moment().isAfter((row.stake_timeend)) && row.stake_active=='1'">  
                <button type="button"  @click="showDepo(row.stake_conversion, row.stake_id, row.id_coin)" class="btn btn-secondary m-2 d-inline-block" >Refund Staking</button>
                </span>
              </CCol>

              <CCol lg="6" xs="12" sm="12" class="d-lg-none  w-100">  
                <CDropdown
                    placement="bottom-end"
                    toggler-text="Convert Earning to USDT"
                    color="success"
                    class="mb-2 d-inline-block w-100"
                  >
                    <CDropdownItem @click="toWallet(row.stake_total, row.stake_id, row.id_coin)">to Wallet</CDropdownItem>
                    <CDropdownDivider/>
                    <CDropdownItem @click="toDeposit(row.stake_total, row.stake_id, row.id_coin)">to Deposits</CDropdownItem>
                  </CDropdown>
              </CCol>

              <CCol lg="6" xs="12" sm="12" class="text-center  d-lg-none">
                <span v-if="moment().isAfter((row.stake_timeend)) && row.stake_active=='1'">  
                <button type="button"  @click="showDepo(row.stake_conversion, row.stake_id, row.id_coin)" class="btn btn-secondary btn-block" >Refund Staking</button>
                </span>
              </CCol>
              </CRow>
          </CCardBody>
        </CCard>

        <CCard accent-color="danger" v-if="itemlengths==0">
          <CCardBody style="font-size: 11px;">
            <CRow>
              <CCol>
                <div class="text-center"><strong>EMPTY STAKE</strong></div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      
        <!-- <table class="table" id="datatablex">
          <thead>
            <tr>
              <th style="width:30px">#</th>
              <th>Coin </th>
              <th>Package </th>
              <th>Amounts (USDT) </th>
              <th>Status </th>
              <th>Date </th>
              <th style="width:60px">Detail</th>
            </tr>
          </thead>
          <tbody>
            
          </tbody>
        </table>   -->

        <CSpinner v-show="mySpinner" color="primary" style="position:fixed;left: 50%;top:50%"/>

    </CCardBody>
  </CCard>


    <CModal title="Error" :show.sync="myError" size="sm">
      Failed! Please check your balance again...
    </CModal>
    <CModal title="Error" :show.sync="myEmpty" size="sm">
      Failed! You have insufficient fund. Please deposit first and to do staking to get Earning.
    </CModal>
    <CModal title="Success" :show.sync="mySuccess" size="sm">
      Success! Your earning balance has been transfered to your Deposit. Please check your deposit balances.
    </CModal>  
    <CModal title="Success" :show.sync="mySuccess2" size="sm">
      Success! Your earning balance has been transfered to your wallet. Please check your wallet balances.
    </CModal>  

    <CModal
      title="Reduce Balance"
      color="dark"
      id="modalDepo2"      
      :show.sync="modalDepo2"
    >
      <p>Do you want to refund your staking contract now?</p>
    <template #footer>
        <h5 class="float-left">Refund Staking : {{amountdr}} USDT</h5>
        <CButton @click="modalDepo2 = false" color="light">Cancel</CButton>
        <CButton @click="depoNow2()" color="success">Process</CButton>
    </template>
    </CModal>
  
  </CCol>
    </CRow>
  </div>
</template>
 
<script>
import moment from 'moment';
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import $ from 'jquery'; 
import { func } from 'prop-types';
let user = JSON.parse(localStorage.getItem('user'));
// let level = user.level;

export default {
  // components:{
  //   moment
  // },
  filters: {
    rupiah: function(value) {
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',

      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  // beforeCreate(){

  //   let date = new Date();
  //     let year = date.getFullYear();
  //     let mnth = date.getMonth();
  //     mnth = +mnth + 1;
  //     let mnth2 = mnth.toString().length>1?mnth:"0"+mnth;
  //     let dt = date.getDate().toString().length>1?date.getDate():"0"+date.getDate();
  //     this.curDate = year+"-"+mnth2+"-"+dt;
  //     console.log("curDate::",this.curDate);
  // },
  mounted(){
    this.loadData();
    this.mmid = this.usr.id_member
    // alert(this.itemlengths);
  },
  data: function() {
        return {
            products:[],
            items:[],
            itemlengths : 0,
            btc:0,
            eth:0,
            mana:0,
            shiba:0,
            doge:0,
            trx:0,
            amountdr:0,
            id:"0",
            mySuccess:false,
            mySuccess2:false,
            myEmpty:false,
            myError:false,
            mySpinner : false,
            modalDepo2:false,
            tahun: new Date().getFullYear(),
            curDate : '',
            usr:JSON.parse(this.$store.state.users)
        }
  },  
  filters: {
  },
  methods: {
    moment: function () {
      return moment();
    },
    showDepo: function(amount, id, cid){
      // alert(amount+" :: "+id);
      this.modalDepo2 = true; 
      if(cid=="1"){
        this.amountdr = (+amount * this.btcSell).toFixed(5);
      }
      else{
        this.amountdr = Number(this.reconvers(+amount, cid)).toFixed(5);
        alert(cid);
      }
      this.id = id;
    },  
    depoNow2: function (event) {
      // Simple POST request with a JSON body using axios
      this.modalDepo2 = false;
      this.mySpinner = true;

      const stk = {
        email: this.usr.id,
        mid: this.mmid,
        id:this.id,
        amount_d: this.amountdr,
      };
      // alert(JSON.stringify(stk));
      if((this.amountdr == null || this.amountdr == "0")) {
        this.myEmpty = true;
      }
      else{
        
        // alert(this.tt);
        axios
          .post(
            process.env.VUE_APP_BASE_URL+"api/public/index.php/adddeporefund/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
            stk
          )
          .then((response) => {
            // console.log(response);
            // alert(JSON.stringify(response.data));
            // this.tabs = response.data;/
                this.mySpinner = false;
            if (response.data == "ok") {
                this.modalDepo2 =false;
            
              // if(idx!=''){
                this.loadData();
              //   this.$router.push("/deposits/detail/"+idx);  
              // }
              // else{
              //   this.myError = true;
              // }
              

              this.mySuccess = true;
            } else {
                this.modalDepo =false;
              
              this.myError = true;
            }
          });
      }
    },
    toWallet: function(val,id, coin) {
      this.mySpinner = true;
      const stk = {
        email: user.id,
        mid: user.id_member,
        amount_d: this.reconvers(val, coin),
        stake_id: id,
      };
      // alert(JSON.stringify(stk));
      if((val == null || val == "0")) {
        this.myEmpty = true;
        this.mySpinner = false;
      }
      else{
        if(coin==1){
          if(val<0.00000001){
              this.myEmpty = true;
              this.mySpinner = false;       
          }
        }
        if(this.type_transaction==2){
          this.tt = "addwithdrawal";
        }else{
          this.tt = "adddepo";
        }
        // alert(this.tt);
        axios
          .post(
            process.env.VUE_APP_BASE_URL+"api/public/index.php/addwallet/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
            stk
          )
          .then((response) => {
                this.mySpinner = false;
            if (response.data == "ok") {
                this.loadData();

              this.mySuccess2 = true;
            } else {
              
              this.myError = true;
            }
          });
      }
    },
    toDeposit: function(val,id, coin) {
      // Simple POST request with a JSON body using axios
      this.mySpinner = true;
      const stk = {
        email: user.id,
        mid: user.id_member,
        amount_d: this.reconvers(val, coin),
        stake_id: id,
      };
      // alert(JSON.stringify(stk));
      if((val == null || val == "0" )) {
        this.myEmpty = true;
        this.mySpinner = false;
      }
      else{
        if(coin==1){
          if(val<0.00000001){
              this.myEmpty = true;
              this.mySpinner = false;       
          }
        }
        if(this.type_transaction==2){
          this.tt = "addwithdrawal";
        }else{
          this.tt = "adddepo";
        }
        // alert(this.tt);
        axios
          .post(
            process.env.VUE_APP_BASE_URL+"api/public/index.php/adddepo3/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
            stk
          )
          .then((response) => {
            // console.log(response);
            // alert(JSON.stringify(response.data));
            // this.tabs = response.data;/
                this.mySpinner = false;
            if (response.data == "ok") {
                // this.modalDepo2 =false;
            
              // if(idx!=''){
                this.loadData();
              //   this.$router.push("/deposits/detail/"+idx);  
              // }
              // else{
              //   this.myError = true;
              // }
              

              this.mySuccess = true;
            } else {
                // this.modalDepo =false;
              
              this.myError = true;
            }
          });
      }
    },
    convers: function(value, arg) {
      var val= "0";
      var cd = "";
      if(arg=='1'){
        val = (+value / this.btc) + " BTC"; 
        cd = "";
      }
      else if(arg=='2'){
        val = (+value / this.eth) + " ETH";  
      }
      else if(arg=='3'){
        val = (+value / this.doge) + " DOGE";  
      }
      else if(arg=='4'){
        val = (+value / this.mana) + " MANA";  
      }
      else if(arg=='5'){
        val = (+value / this.shiba) + " SHIB";  
      }
      else if(arg=='6'){
        val = (+value / this.trx) + " TRX";  
      }
      else{
        val = "";  
      }
      var val2 = (Math.round(parseFloat(val) * 100000000))/100000000;
      // alert(+val2);
      // var val4 = val;
      // var val2 = val.split(".");
      // var val3 = val2[1].substr(0,8);
      // val = val2[0]+"."+val3;
      val = parseFloat(val2);

      return val;
    },
    reconvers: function(value, arg) {
      var val= "0";
      var cd = "";
      if(arg=='1'){
        val = (+value * this.btc) ; 
        cd = "";
      }
      else if(arg=='2'){
        val = (+value * this.eth) ;
      }
      else if(arg=='3'){
        val = (+value * this.doge);
      }
      else if(arg=='4'){
        val = (+value * this.mana);
      }
      else if(arg=='5'){
        val = (+value * this.shiba);  
      }
      else if(arg=='6'){
        val = (+value * this.trx);  
      }
      else{
        val = "";  
      }
      var val2 = (Math.round(parseFloat(val) * 100000000))/100000000;
      // alert(+val2);
      // var val4 = val;
      // var val2 = val.split(".");
      // var val3 = val2[1].substr(0,8);
      // val = val2[0]+"."+val3;
      val = parseFloat(val2);

      return val;
    },
    loadData: function() {
      
      let mid = user.id_member;
      let id_member = user.id_member;
          let idm;
          if(id_member=="0") {
            idm = "";
          }
          else{
            idm = id_member;
          }
            axios
            .get(
              process.env.VUE_APP_BASE_URL+"api/public/index.php/dashboardstat/"+idm+"?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
              user
            )
            .then((response) => {
              // if (response.data == "sukses") {
                
                this.wallet = parseFloat(response.data.wallet);
                
                user.wallet=response.data.wallet;
            });

            axios
            .get(
              "https://indodax.com/api/summaries",
              false
            )
            .then((response) => {
              // if (response.data == "sukses") {
                
                this.eth = parseFloat(response.data.tickers.eth_usdt.buy);
                this.btc = parseFloat(response.data.tickers.btc_usdt.buy);
                this.btcSell = parseFloat(response.data.tickers.btc_usdt.sell);
                // alert(this.btc);
                // this.doge = 0+" USDT";
                // this.mana = 0+" USDT";
               
            });

            axios
            .get(
              "https://api1.binance.com/api/v3/ticker/price?symbol=DOGEUSDT",
              false
            )
            .then((response) => {
              this.doge = parseFloat(response.data.price);              
            });

            axios
            .get(
              "https://api1.binance.com/api/v3/ticker/price?symbol=MANAUSDT",
              false
            )
            .then((response) => {
              this.mana = parseFloat(response.data.price);              
            });

            axios
            .get(
              "https://api1.binance.com/api/v3/ticker/price?symbol=SHIBUSDT",
              false
            )
            .then((response) => {
              this.shiba = parseFloat(response.data.price);              
            });

            axios
            .get(
              "https://api1.binance.com/api/v3/ticker/price?symbol=TRXUSDT",
              false
            )
            .then((response) => {
              this.trx = parseFloat(response.data.price);              
            });

        //     setTimeout(() => 
        // , 7000);
        axios
        .post(
          process.env.VUE_APP_BASE_URL+"api/public/index.php/mystake/"+mid+"?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",false
        )
        .then((response) => {
          this.items=response.data.data;
          this.itemlengths = this.items.length;
          // alert(JSON.stringify(this.items));
        });
        
      if (this.tahun == null){
          $('#datatable').DataTable({
            "processing": true,
              "serverSide": true,
               "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"api/public/index.php/mystake/"+mid+"?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no" },
                  { "data": "coin_name" },
                  { "data": "package_name" },
                  { "data": "stake_amount", className: "text-center" },
                  { "data": "stake_status", className: "text-center" },
                  { "data": "stake_timecreated", className: "text-center" },
                  { "data": "stake_timeend", className: "text-center" },
                  { "data": "detail" },
              ]  

          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      else{
          $('#datatable').DataTable({
                      "processing": true,
              "serverSide": true,
               "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"api/public/index.php/mystake/"+mid+"?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no" },
                  { "data": "coin_name" },
                  { "data": "package_name" },
                  { "data": "stake_amount", className: "text-center" },
                  { "data": "stake_status", className: "text-center" },
                  { "data": "stake_timecreated", className: "text-center" },
                  { "data": "detail" },
              ] 
 
          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      
    }
  }
}
</script>